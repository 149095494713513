 ::-webkit-scrollbar {
   width: 6px !important;
 }

 /* this targets the default scrollbar (compulsory) */

 /* Track */
 ::-webkit-scrollbar-track {
   background: transparent !important;
 }

 /* Hide Number Input Roller */
 input[type=number]::-webkit-inner-spin-button,
 input[type=number]::-webkit-outer-spin-button {
   -webkit-appearance: none;
   margin            : 0;
 }

 /* Handle */
 ::-webkit-scrollbar-thumb {
   background   : rgba(0, 0, 0, 0.23) !important;
   border-radius: 24px;
   transition   : all 0.5s;
   cursor       : pointer;
 }

 /* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
   background: rgba(146, 151, 165, 1) !important;
 }

 /* this will style the thumb, ignoring the track */

 ::-webkit-scrollbar-button {
   background-color: transparent !important;
 }

 /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

 ::-webkit-scrollbar-corner {
   background-color: transparent !important;
 }

 /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */
 @font-face {
   font-family: 'IPMO_bold';
   /* src        : url('./font/Poppins-Bold.ttf'); */
   src: local("AssistantRegular"), url('./font/Poppins-Bold.ttf') format("truetype") ;
   font-display: swap ;
 }

 @font-face {
   font-family: 'IPMO_medium';
   /* src        : url('./font/Poppins-Medium.ttf'); */
   src: local("AssistantRegular"), url('./font/Poppins-Medium.ttf') format("truetype") ;
   font-display: swap ;
 }

 @font-face {
   font-family: 'IPMO_regular';
   /* src        : url('./font/Poppins-Regular.ttf'); */
   src: local("AssistantRegular"),url('./font/Poppins-Regular.ttf') format("truetype");
   font-display: swap ;
 }

 /* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_9785">Text with this font applied</p> */
 *,
 *::before,
 *::after {
   box-sizing: border-box;
   margin:0px
 }

 html {
   height: 100%;
 }

 #root {
   height: 100%;
 }

 .datepicker-button-previous, .datepicker-button-next,.date-day-Item-selected{
  background-color:  rgb(54, 105, 238) !important;
  border:2px solid  white !important;
  color: white;
 }
 .scroll-head{
  display: none;
 }
 .datepicker-button-previous, .datepicker-button-next{
  background-color:  rgb(54, 105, 238) !important;
 }
 .datepicker-datelist-scrollable{
  justify-content: center !important;
 }
 .datepicker-month-label{
  display: none !important;
 }
 .wrapper{
  display: flex;
 }
 .date-day-Item-selected{
  color: whitesmoke !important;
 }
 .datepicker-date-day-Item:hover{
  background-color: rgb(9, 29, 80) !important;
 }

 .ql-toolbar.ql-snow{
  border-radius: 4px 4px 0 0 !important;
 }

 .ql-toolbar.ql-snow + .ql-container.ql-snow{
  border-radius: 0 0 4px 4px !important;
 }
 .ql-container.ql-snow{
  border-radius: 0 0 4px 4px !important;
 }

